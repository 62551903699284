<template>
  <div id="quiz-menu">
    <div class="row">
      <MenuItem v-for="(item, i) in Menu_items" :key="i" v-bind="item" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuItem from "@/components/MenuItem.vue";

export default {
  name: "SoloMenu",
  components: {
    MenuItem
  },
  data() {
    return {
      Menu_items: [
        {
          name: "Solo",
          size: "4",
          color: "primary",
          textcolor: "light",
          image: "fa-user.svg",
          link: "/solo"
        },
        {
          name: "2 players",
          size: "4",
          color: "secondary",
          textcolor: "light",
          image: "fa-user.svg",
          link: "/wip/6"
        },
        {
          name: "Multi players",
          size: "4",
          color: "egshell",
          textcolor: "light",
          image: "fa-users.svg",
          link: "/wip/7"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#quiz-menu {
  @extend .text-right;
  width: 100vw;
  height: 75vh !important;
  @extend .px-3;
  @extend .m-0;

  .row {
    @extend .pt-1;
  }
}
</style>
